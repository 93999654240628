import React from "react";
import Navigation from "../components/global/navigation";
import Footer from "../components/global/footer";

// CSS
import "../styles.css"
import SEO from "../components/global/seo";

const UnderConstruction = () => {
   return (
      <React.Fragment>
         <SEO
            title="This page is under construction"
            slug="under-construction"
            metaDescription="This page is under construction"
            image=""
            titleTemplate="%s"
         />

         {/* Content */}
         <Navigation/>

         <div className="standard-container">
            <div className="special-page-split-container narrow-width">
               <span style={{alignSelf: 'center'}}>
                  <h1>Under construction</h1>
                  <p>This is awkward... but this page isn't ready yet. We'll get things in order as quickly as you can say "£14.25 appliance cover"! 😮</p>
                  
                  <br/>

                  <a className="standard-button primary" href="/">Back to homepage</a>
               </span>

               <img src="/images/under-construction-image.png"/>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default UnderConstruction